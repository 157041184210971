import { FullscreenAppCustomProps } from '@wpp-open/core'
import { useEffect } from 'react'

import { environment } from 'environment'
import { useAppsContext } from 'providers/apps/AppsContext'
import { MicroAppLibraryType } from 'types/apps/microApps'
import { AppConfig } from 'types/common/singleSpa'
import { registerApp, unregisterApp } from 'utils/singleSpa'

const APP_CODE = 'OS-ASSISTANT'

const getAppConfig = (customProps: FullscreenAppCustomProps): AppConfig => ({
  stableId: APP_CODE,
  libraryType: MicroAppLibraryType.SystemJS,
  windowLibraryName: null,
  bundleUrl: environment.AI_ASSISTANT_BUNDLE_URL,
  activeWhen: () => true,
  customProps,
})

export const OsAssistant = () => {
  const { getFullscreenAppCustomProps } = useAppsContext()

  useEffect(() => {
    const customProps: FullscreenAppCustomProps = {
      ...getFullscreenAppCustomProps({ stableId: APP_CODE }),
      domElementGetter: () => document.getElementById('chat-assistant')!,
    }

    registerApp(getAppConfig(customProps))

    return () => {
      unregisterApp({ stableId: APP_CODE })
    }
  }, [getFullscreenAppCustomProps])

  return <div id="chat-assistant" />
}
