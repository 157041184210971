import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/smallScreenBoundary/SmallScreenBoundary.module.scss'
import { SvgSmallScreen } from 'components/svg/renderError/SvgSmallScreen'

export const SmallScreenBoundary = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation()

  return (
    <>
      <Flex
        className={clsx(styles.overlay, {
          [styles.disabled]: process.env.DEV,
        })}
        direction="column"
        align="center"
        justify="center"
        data-testid="small-screen-boundary"
      >
        <SvgSmallScreen />

        <Flex className={styles.textWrapper} direction="column" align="center" justify="center">
          <WppTypography type="m-strong">{t('os.error_state.small_screen_error.main_title')}</WppTypography>
          <WppTypography type="s-body" className={styles.subTitle}>
            {t('os.error_state.small_screen_error.sub_title')}
          </WppTypography>
        </Flex>
      </Flex>

      {children}
    </>
  )
}
