import { WppDivider, WppIconChevron, WppTypography } from '@platform-ui-kit/components-library-react'
import {
  AnalyticsActionType,
  HierarchyContainerNodeId,
  ProjectsContainerNodeId,
  TenantConfigNavigationType,
  AppInstanceAssignmentType,
} from '@wpp-open/core'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { useGetDevHubLogoUrl, useHierarchyNodeAppInstances } from 'components/hierarchyAppsManagement/utils'
import { AppNavigationMenuItem } from 'components/navigationMenu/navigationMenuContent/appMenuItem/AppNavigationMenuItem'
import styles from 'components/navigationMenu/navigationMenuContent/composedNavigationMenuColumn/ComposedNavigationMenuColumn.module.scss'
import { NavigationMenuColumnHeader } from 'components/navigationMenu/navigationMenuContent/navigationMenuColumnHeader/NavigationMenuColumnHeader'
import { useNavigationMenuController } from 'components/navigationMenu/navigationMenuContent/NavigationMenuControllerContext'
import { NavigationMenuTreeListColumn } from 'components/navigationMenu/navigationMenuContent/navigationMenuTreeListColumn/NavigationMenuTreeListColumn'
import { CustomNavigationAppsList } from 'components/treeList/customNavigationAppsList/CustomNavigationAppsList'
import { TreeListItem } from 'components/treeList/treeListItem/TreeListItem'
import { LaunchLocations } from 'constants/analytics'
import { DEFAULT_PLURAL_COUNT } from 'constants/i18n'
import { useRedirectToDefaultHubOrSystemHub } from 'hooks/useRedirectToDefaultHubOrHomePage'
import { useHeaderNavigation } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { HubType } from 'types/hubs/hubs'
import { trackAnalytics, trackAppOpenAnalytics } from 'utils/analytics'
import { getAppInstanceLogo } from 'utils/appInstances'
import { EVENTS, getEventPayload } from 'utils/events'
import { useGetHierarchyLevelLabel } from 'utils/mapping/common'
import { getIsHierarchyColumnVisible, getIsProjectsColumnVisible } from 'utils/mapping/navigationTree'
import { isNavigationTypeEnabled } from 'utils/navigation'
import { routesManager } from 'utils/routesManager'

export const ComposedNavigationMenuColumn = () => {
  const { t } = useTranslation()
  const { currentTenant, navigationHierarchy } = useCurrentTenantData()
  const { closeNavigation } = useOsState()
  const { selectedNavigationNodeId, selectNavigationNode } = useNavigationMenuController()
  const { nodesMapping } = useHeaderNavigation()
  const getHierarchyLevelLabel = useGetHierarchyLevelLabel()
  const { redirectToDefaultHubOrSystemHub, hub } = useRedirectToDefaultHubOrSystemHub()
  const { userDetails } = useOtherTenantsAndUserData()

  const homePageUrl = routesManager.home.root.url()
  const homeMatch = useMatch(`${homePageUrl}/*`)
  const pointerNode = nodesMapping[selectedNavigationNodeId]

  const firstHierarchyLevelLabel = getHierarchyLevelLabel({
    levelType: navigationHierarchy[0]?.type || null,
    isPlural: true,
  })

  const isHierarchyNavigationEnabled = isNavigationTypeEnabled({
    type: TenantConfigNavigationType.Hierarchy,
    currentTenant,
  })

  const isProjectsNavigationEnabled = isNavigationTypeEnabled({
    type: TenantConfigNavigationType.Projects,
    currentTenant,
  })

  const isCustomNavigationEnabled = isNavigationTypeEnabled({
    type: TenantConfigNavigationType.Custom,
    currentTenant,
  })

  const handleTrackAnalytics = (productName: string) => {
    trackAppOpenAnalytics({
      productName,
      productType: EVENTS.OS_MODULES.PRODUCT_TYPE,
      userDetails,
      launchedFrom: LaunchLocations.HamburgerMenu,
      hub_id: hub?.id,
      hub_name: hub?.name,
      is_system_hub: hub?.type === HubType.System,
    })
  }

  return (
    <NavigationMenuTreeListColumn data-testid="composed-navigation-menu-column">
      <NavigationMenuColumnHeader>
        <WppTypography type="l-strong" data-testid="composed-navigation-menu-column-title">
          {currentTenant.name}
        </WppTypography>
      </NavigationMenuColumnHeader>

      <Flex gap={2} direction="column">
        <TreeListItem
          checked={!!homeMatch}
          onClick={() => {
            redirectToDefaultHubOrSystemHub()
            closeNavigation()
            handleTrackAnalytics(EVENTS.OS_MODULES.PRODUCT_NAME.HOME_PAGE)
          }}
        >
          <span slot="label">{t('os.header.home')}</span>
        </TreeListItem>

        {isProjectsNavigationEnabled && (
          <TreeListItem
            checked={getIsProjectsColumnVisible(pointerNode)}
            onClick={e => {
              if (!e.currentTarget.checked) {
                selectNavigationNode(ProjectsContainerNodeId)
              }

              trackAnalytics({
                type: AnalyticsActionType.action,
                payload: getEventPayload(EVENTS.HAMBURGER_MENU.ACTIONS.ACTION_HUMBURGER_MENU_ITEM, 'project'),
              })
            }}
          >
            <span slot="label">{t('os.entities.project', { count: DEFAULT_PLURAL_COUNT })}</span>
            <WppIconChevron slot="right" direction="right" />
          </TreeListItem>
        )}

        {isHierarchyNavigationEnabled && (
          <TreeListItem
            checked={getIsHierarchyColumnVisible({ pointerNode, navigationHierarchy })}
            onClick={() => {
              selectNavigationNode(HierarchyContainerNodeId)

              trackAnalytics({
                type: AnalyticsActionType.action,
                payload: getEventPayload(
                  EVENTS.HAMBURGER_MENU.ACTIONS.ACTION_HUMBURGER_MENU_ITEM,
                  firstHierarchyLevelLabel,
                ),
              })
            }}
          >
            <span slot="label">{firstHierarchyLevelLabel}</span>
            <WppIconChevron slot="right" direction="right" />
          </TreeListItem>
        )}

        {isCustomNavigationEnabled && (
          <>
            <WppDivider className={styles.divider} />
            <CustomNavigationMenuSection />
          </>
        )}
      </Flex>
    </NavigationMenuTreeListColumn>
  )
}

function CustomNavigationMenuSection() {
  const { t } = useTranslation()
  const { navigationTree, currentTenant } = useCurrentTenantData()
  const { selectedCustomMenuGroupId, selectCustomMenuGroup } = useNavigationMenuController()

  const { data: appInstances, isLoading: isAppInstancesLoading } = useHierarchyNodeAppInstances({
    params: {
      assignmentId: navigationTree.rootId,
      assignmentType: AppInstanceAssignmentType.Custom,
    },
  })

  const getDevHubLogoUrl = useGetDevHubLogoUrl(appInstances)

  const isHierarchyNavigationEnabled = isNavigationTypeEnabled({
    type: TenantConfigNavigationType.Hierarchy,
    currentTenant,
  })

  const isProjectsNavigationEnabled = isNavigationTypeEnabled({
    type: TenantConfigNavigationType.Projects,
    currentTenant,
  })

  const visibleAppInstances = appInstances.filter(app => app.flags.visible)

  return (
    <div data-testid="custom-navigation-menu-list">
      <CustomNavigationAppsList
        isLoading={isAppInstancesLoading}
        selectedGroupId={selectedCustomMenuGroupId}
        onSelectGroup={selectCustomMenuGroup}
        appInstances={visibleAppInstances}
        renderApp={appInstance => (
          <AppNavigationMenuItem
            logoUrl={getAppInstanceLogo({ appInstance, getDevHubLogoUrl })}
            appInstance={appInstance}
          />
        )}
        emptyState={
          <>
            {!isHierarchyNavigationEnabled && !isProjectsNavigationEnabled && (
              <WppTypography data-testid="no-items-label" className={styles.emptyState} type="s-midi">
                {t('os.navigation_menu.no_custom_menu_items')}
              </WppTypography>
            )}
          </>
        }
      />
    </div>
  )
}
