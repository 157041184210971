import { NavigationState } from '@platform-ui-kit/components-library'
import { MayBeNull } from '@wpp-open/core'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { HELP_LINKS_BY_APP_TAG } from 'components/zendesk/utils/constants'
import { KNOWLEDGE_BASE_HOME_URL, OS_STATUS_PAGE_URL } from 'constants/common'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'
import { LegacyAppWindowLibraryName } from 'legacy/types/apps'
import { useOsState } from 'providers/osState/OsStateProvider'
import { useOsSupportService } from 'providers/osSupportService/OsSupportServiceContext'
import { SystemAppCode } from 'types/apps/microApps'
import { HubsType, HubType } from 'types/hubs/hubs'
import { AppData, AppDataType } from 'types/osState/appDataResolved'
import { excludeFalsy } from 'utils/common'
import { routesManager } from 'utils/routesManager'

interface HeaderNavigationItem extends Omit<NavigationState, 'label'> {
  label: string
}

const KNOWLEDGE_BASE_URL_APP_CONFIG_KEY = 'KnowledgeBaseURL'

export enum HelpNavigationValue {
  Community = 'community',
  KnowledgeBase = 'knowledge_base',
  Support = 'support',
  OsStatus = 'os_status',
  Marketplace = 'marketplace-help',
  Intercom = 'intercom',
}

export const isHelpNavigationItem = (value: string) =>
  Object.values(HelpNavigationValue).includes(value as HelpNavigationValue)

export type HeaderAppTag = SystemAppCode | LegacyAppWindowLibraryName

export const getHeaderAppTag = ({ type, app }: AppData): MayBeNull<HeaderAppTag> => {
  if (type === AppDataType.SystemMicroApp) {
    return app.stableId
  }

  if (type === AppDataType.ExternalLegacyMicroApp) {
    return app.windowLibraryName
  }

  return null
}

const useHeaderHelpSubItems = () => {
  const { zendesk, intercom } = useOsSupportService()
  const { appData } = useOsState()
  const appTag = getHeaderAppTag(appData)
  const { appCustomConfig } = appData
  const customKnowledgeBaseUrl = appCustomConfig?.[KNOWLEDGE_BASE_URL_APP_CONFIG_KEY]

  const { t } = useTranslation()

  return useMemo(() => {
    const helpLinks = appTag ? HELP_LINKS_BY_APP_TAG[appTag] : null

    return [
      !!customKnowledgeBaseUrl && {
        label: t('os.help.app_knowledge_base'),
        value: HelpNavigationValue.KnowledgeBase,
        path: customKnowledgeBaseUrl,
      },
      !!helpLinks?.communityUrl &&
        zendesk.active && {
          label: t('os.header.applications.community'),
          value: HelpNavigationValue.Community,
          path: helpLinks.communityUrl,
        },
      !customKnowledgeBaseUrl && {
        label: t('os.help.knowledge_base'),
        value: HelpNavigationValue.KnowledgeBase,
        path: helpLinks?.knowledgeBaseUrl || KNOWLEDGE_BASE_HOME_URL,
      },
      !intercom.active && {
        label: t('os.help.os_status'),
        value: HelpNavigationValue.OsStatus,
        path: OS_STATUS_PAGE_URL,
      },
      intercom.active && {
        label: t('os.help.get_support'),
        value: HelpNavigationValue.Intercom,
      },
      zendesk.active && {
        label: t('os.help.get_support'),
        value: HelpNavigationValue.Support,
      },
    ].filter(excludeFalsy)
  }, [appTag, zendesk.active, t, intercom.active, customKnowledgeBaseUrl])
}

export const useHeaderMenu = () => {
  const { t } = useTranslation()
  const { hasPermission } = useHasPermission()
  const helpNavigation = useHeaderHelpSubItems()

  return useMemo(() => {
    const headerNavigation: HeaderNavigationItem[] = [
      !!helpNavigation.length && {
        label: t('os.header.applications.help'),
        value: 'help',
        children: helpNavigation,
      },
      {
        label: t('os.header.applications.news'),
        value: 'news',
        path: routesManager.systemApps.news.root(),
      },
      hasPermission(Permission.WPP_OPEN_MARKETPLACE_APP_ACCESS) && {
        label: t('os.header.applications.marketplace'),
        value: 'marketplace',
        path: routesManager.systemApps.marketplace(),
      },
      hasPermission(Permission.WPP_DEVHUB_APP_ACCESS) && {
        label: t('os.header.applications.devhub'),
        value: 'devhub',
        path: routesManager.systemApps.devhub(),
      },
      hasPermission(Permission.NETWORK_APP_ACCESS) && {
        label: t('os.header.applications.network'),
        value: 'network',
        path: routesManager.network.root.url(),
      },
    ].filter(excludeFalsy)

    return headerNavigation
  }, [helpNavigation, t, hasPermission])
}

interface GetFilteredHubs {
  hubs: HubsType[]
  hasPermission: boolean
}

export const getFilteredHubs = ({ hubs, hasPermission }: GetFilteredHubs) => {
  const filteredHubs = hubs.filter(({ type }) => type !== HubType.System)

  return hasPermission ? filteredHubs : hubs.filter(({ isActive }) => isActive)
}
