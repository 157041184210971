import { useEffect, useState } from 'react'
import { addErrorHandler, AppError, removeErrorHandler } from 'single-spa'

import { isCriticalMicroAppError, unregisterApp } from 'utils/singleSpa'

export const useMicroAppErrorHandler = () => {
  // TODO: Use useDispatchRenderError() instead to directly trigger an error
  const [isMicroAppError, setIsMicroAppError] = useState(false)

  useEffect(() => {
    const errorHandler = (error: AppError) => {
      if (isCriticalMicroAppError(error)) {
        console.error(`Fullscreen App '${error.appOrParcelName}' has encountered an error and crashed`)

        unregisterApp({ stableId: error.appOrParcelName })
        setIsMicroAppError(true)
      }
    }

    addErrorHandler(errorHandler)

    return () => {
      removeErrorHandler(errorHandler)
    }
  }, [])

  return {
    isMicroAppError,
  }
}
