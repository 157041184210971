import {
  start,
  getAppNames,
  getAppStatus,
  registerApplication,
  unregisterApplication,
  AppError,
  LifeCycles,
  LOAD_ERROR,
  SKIP_BECAUSE_BROKEN,
  ParcelConfigObject,
} from 'single-spa'

import { MicroAppLibraryType } from 'types/apps/microApps'
import { AppConfig, AppBundleConfig } from 'types/common/singleSpa'

const isRegistered = (code: string) => getAppNames().includes(code)

export const loadPageScript = (url: string): Promise<any> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = url
    script.onload = resolve

    script.onerror = () => {
      script.remove()
      reject()
    }

    document.head.appendChild(script)
  })

const getAppLoader =
  ({ libraryType, windowLibraryName, bundleUrl }: AppBundleConfig) =>
  (): Promise<LifeCycles> => {
    switch (libraryType) {
      case MicroAppLibraryType.SystemJS: {
        return System.import(bundleUrl)
      }

      case MicroAppLibraryType.Window: {
        const libraryStorage = window as unknown as Record<string, LifeCycles>

        if (libraryStorage[windowLibraryName!]) {
          return Promise.resolve(libraryStorage[windowLibraryName!])
        }

        return loadPageScript(bundleUrl).then(() => libraryStorage[windowLibraryName!])
      }

      case MicroAppLibraryType.ESM: {
        return import(/* webpackIgnore: true */ bundleUrl)
      }

      default:
        throw new Error('Unsupported libraryType')
    }
  }

export const loadParcelConfig = async (appBundleConfig: AppBundleConfig): Promise<ParcelConfigObject> => {
  const parcel = await getAppLoader(appBundleConfig)()

  return {
    name: appBundleConfig.stableId,
    ...parcel,
  }
}

export const registerApp = (appConfig: AppConfig) => {
  const { stableId, activeWhen, customProps } = appConfig

  if (!isRegistered(stableId)) {
    registerApplication({
      name: stableId,
      app: getAppLoader(appConfig),
      activeWhen,
      customProps,
    })
  }
}

export const unregisterApp = async ({ stableId }: Pick<AppConfig, 'stableId'>) => {
  try {
    if (isRegistered(stableId)) {
      await unregisterApplication(stableId)
      console.warn(`App '${stableId}' was unregistered`)
    }
  } catch (error) {
    console.error(`Failed to unregister '${stableId}' app`, error)
  }
}

export const unregisterAllApps = async () => {
  try {
    await Promise.all(getAppNames().map(app => unregisterApplication(app)))
  } catch (error) {
    console.error('Failed to unregister all apps', error)
  }
}

export const isCriticalMicroAppError = (error: AppError) => {
  const { appOrParcelName } = error
  const appStatus = getAppStatus(appOrParcelName)

  return [SKIP_BECAUSE_BROKEN, LOAD_ERROR].includes(appStatus!)
}

export const runSingleSpa = () => {
  start({
    // true by default for single-spa v6+
    urlRerouteOnly: true,
  })
}
