import { systemApps } from 'constants/apps'
import { GENERIC_TENANT_DEV_ROUTE } from 'constants/routes'
import { SystemAppCode } from 'types/apps/microApps'
import { join } from 'utils/common'
import { getAppInstanceUrl, getDefaultLocalAppUrl } from 'utils/navigation'

const genericRoutePath = process.env.DEV ? `${GENERIC_TENANT_DEV_ROUTE}` : ''
const getGenericRouteUrlFn = (childPath?: string) => () => `/${join([genericRoutePath, childPath], '/')}`

const genericRoutesManager = {
  root: {
    routePath: genericRoutePath,
    url: getGenericRouteUrlFn(),
  },
  selectOs: {
    routePath: 'select-os',
    url: getGenericRouteUrlFn('select-os'),
  },
  landing: {
    aboutOpen: {
      routePath: 'about-open',
      url: getGenericRouteUrlFn('about-open'),
    },
    whatWeOffer: {
      routePath: 'what-we-offer',
      url: getGenericRouteUrlFn('what-we-offer'),
    },
    ourProducts: {
      routePath: 'our-products',
      url: getGenericRouteUrlFn('our-products'),
    },
    successStories: {
      routePath: 'success-stories',
      url: getGenericRouteUrlFn('success-stories'),
    },
    selectOs: {
      routePath: 'select-os',
      url: getGenericRouteUrlFn('select-os'),
    },
    termsConditions: {
      routePath: 'terms-conditions',
      url: getGenericRouteUrlFn('terms-conditions'),
    },
    contactUs: {
      routePath: 'contact-us',
      url: getGenericRouteUrlFn('contact-us'),
    },
  },
}

export const routesManager = {
  systemApps: {
    news: {
      root: () => `/${systemApps[SystemAppCode.News].osRoute}`,
      article: (id: string) => `/${systemApps[SystemAppCode.News].osRoute}/${id}`,
      settings: () => `/${systemApps[SystemAppCode.News].osRoute}/settings`,
    },
    masterData: () => `/${systemApps[SystemAppCode.MasterData].osRoute}`,
    devhub: () => `/${systemApps[SystemAppCode.DevHub].osRoute}`,
    marketplace: () => `/${systemApps[SystemAppCode.Marketplace].osRoute}`,
    themeBuilder: () => `/${systemApps[SystemAppCode.ThemeBuilder].osRoute}`,
    orchestration: {
      root: () => `/${systemApps[SystemAppCode.Orchestration].osRoute}`,
      projects: () => `/${systemApps[SystemAppCode.Orchestration].osRoute}/projects`,
      project: (projectId: string) => `/${systemApps[SystemAppCode.Orchestration].osRoute}/project/${projectId}`,
      taskPreview: (projectId: string, taskId: string) =>
        `/${systemApps[SystemAppCode.Orchestration].osRoute}/project/${projectId}/tasks?view=taskPreview&id=${taskId}`,
      templates: () => `/${systemApps[SystemAppCode.Orchestration].osRoute}/templates`,
      template: (templateId: string) =>
        `/${systemApps[SystemAppCode.Orchestration].osRoute}/templates?view=template&id=${templateId}`,
    },
    local: getDefaultLocalAppUrl,
  },
  generic: genericRoutesManager,
  app: getAppInstanceUrl,
  authCallback: {
    routePath: 'authentication/callback',
    url: () => '/authentication/callback',
  },
  login: {
    routePath: 'login',
    url: () => '/login',
  },
  public: {
    terms: {
      routePath: 'terms',
      url: () => '/terms',
    },
  },
  miroAuthCallback: {
    routePath: 'miro-auth-callback',
    url: () => '/miro-auth-callback',
  },
  home: {
    root: {
      routePath: 'home',
      url: () => '/home',
    },
    edit: {
      routePath: 'edit',
      url: () => '/home/edit',
    },
  },
  hubs: {
    root: {
      routePath: 'hubs/:hubId/home',
      url: (hubId: string) => `/hubs/${hubId}/home`,
    },
    edit: {
      routePath: 'edit',
      url: (hubId: string) => `/hubs/${hubId}/home/edit`,
    },
  },
  links: {
    routePath: 'hubs/:hubId/links',
    url: (hubId: string) => `/hubs/${hubId}/links`,
  },
  user: {
    root: {
      routePath: 'user',
      url: () => '/user',
    },
    profile: {
      routePath: 'profile',
      url: () => '/user/profile',
    },
  },
  network: {
    root: {
      routePath: 'network',
      url: () => '/network',
    },
    contacts: {
      root: {
        routePath: 'contacts',
        url: () => '/network/contacts',
      },
      primary: {
        routePath: 'primary',
        url: () => '/network/contacts/primary',
      },
    },
  },
  admin: {
    root: {
      routePath: 'admin',
      url: () => '/admin',
    },
    navigation: {
      routePath: 'navigation',
      url: () => '/admin/navigation',
    },
    hubs: {
      routePath: 'hubs',
      url: () => '/admin/hubs',
    },
    platformConfiguration: {
      routePath: 'platform-config',
      url: () => '/admin/platform-config',
    },
    members: {
      root: {
        routePath: 'members',
        url: () => '/admin/members',
      },
      groups: {
        routePath: 'groups',
        url: () => '/admin/members/groups',
      },
    },
    roles: {
      routePath: 'roles',
      url: () => '/admin/roles',
    },
    hierarchy: {
      root: {
        routePath: 'hierarchy',
        url: () => '/admin/hierarchy',
      },
      settings: {
        routePath: 'settings',
        url: () => '/admin/hierarchy/settings',
      },
    },
    apps: {
      root: {
        routePath: 'apps',
        url: () => '/admin/apps',
      },
    },
    requests: {
      root: {
        routePath: 'requests',
        url: () => '/admin/requests',
      },
      tenantAccess: {
        root: {
          routePath: 'tenant-access',
          url: () => '/admin/requests/tenant-access',
        },
        completed: {
          routePath: 'completed',
          url: () => '/admin/requests/tenant-access/completed',
        },
      },
      hierarchyAccess: {
        root: {
          routePath: 'hierarchy-access',
          url: () => '/admin/requests/hierarchy-access',
        },
        completed: {
          routePath: 'completed',
          url: () => '/admin/requests/hierarchy-access/completed',
        },
      },
      hubAccess: {
        root: {
          routePath: 'hub-access',
          url: () => '/admin/requests/hub-access',
        },
      },
      signUp: {
        root: {
          routePath: 'sign-up',
          url: () => '/admin/requests/sign-up',
        },
        completed: {
          routePath: 'completed',
          url: () => '/admin/requests/sign-up/completed',
        },
      },
    },
    notifications: {
      routePath: 'notifications',
      url: () => '/admin/notifications',
    },
    settings: {
      routePath: 'settings',
      url: () => '/admin/settings',
    },
  },
}
