import clsx from 'clsx'
import { Outlet } from 'react-router-dom'

import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { useAppsContext } from 'providers/apps/AppsContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import styles from 'routes/specificTenantRoutes/securedRoutesContainer/SecuredRoutesContainer.module.scss'
import { AppDataType } from 'types/osState/appDataResolved'

export const SecuredRoutesContainer = () => {
  const { isFullscreenMicroAppActive } = useAppsContext()
  const { appData } = useOsState()

  const isLoading = appData.type === AppDataType.Loading

  return (
    <>
      {/**
       * Note: In some cases single-spa routing happens faster than React re-renders,
       * so this MicroApp container is always available
       * */}
      <div
        className={clsx(styles.microAppContainer, {
          [styles.hidden]: !isFullscreenMicroAppActive || isLoading,
        })}
        id="micro-app"
      />

      {isLoading ? <LoadingPage /> : <Outlet />}
    </>
  )
}
